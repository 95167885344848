import React from 'react';

import { Link } from 'gatsby';

import Footer from '../components/Footer';
import Seo from '../components/Seo';

import '../styles/index.scss';

const NotFoundPage = () => {
  return (
    <>
      <Seo title="Home" />
      <header className="i-header">
        <nav className="i-header__nav">
          <ul>
            <li>
              <Link
                className="eye-link"
                style={{ color: '#b635f6 ' }}
                title="Home"
                to="/"
              >
                Home
              </Link>
            </li>
          </ul>
        </nav>
      </header>
      <main role="main" className="i-page">
        <section className="i-container">
          <div
            className="i-container__text"
            style={{ paddingTop: 150, paddingBottom: 150 }}
          >
            <h2>Ooops! Página não encontrada</h2>
            <p>A página solicitada não existe.</p>
            <p>Use o link no menu superior para voltar a página principal.</p>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default NotFoundPage;
